// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import Tabs from './../../molecules/Tabs/Tabs';
import SummaryContent from './../../organisms/TablesContent/SummaryContent';
import ConversionContent from './../../organisms/TablesContent/ConversionContent';
import BrandsContent from './../../organisms/TablesContent/BrandsContent';
import TrafficContent from './../../organisms/TablesContent/TrafficContent';
import PaymentSummary from './../../organisms/TablesContent/PaymentSummary';
import PaymentBalance from './../../organisms/TablesContent/PaymentBalance';
import ReferralLinks from './../../organisms/TablesContent/ReferralLinks';

interface Props extends ThemeProps {
  defaultTab?: number;
}

const Container = styled('div', {
  width: '100%',
  maxWidth: '100%',
});

const Reports: React.FC<Props> = ({ defaultTab, ...props }) => {
  let tabList = [
    {
      label: 'Summary',
      content: <SummaryContent />,
    },
    {
      label: 'Brands',
      content: <BrandsContent />,
    },
    {
      label: 'Conversion',
      content: <ConversionContent />,
    },
    {
      label: 'Traffic',
      content: <TrafficContent />,
    },
    {
      label: 'Payment', // payment summary
      content: <PaymentSummary />,
    },
  ];
  if (
    window.localStorage.level === 'account_manager' ||
    window.localStorage.level === 'admin'
  ) {
    tabList = [
      {
        label: 'Summary',
        content: <SummaryContent />,
      },
      {
        label: 'Brands',
        content: <BrandsContent />,
      },
      {
        label: 'Conversion',
        content: <ConversionContent />,
      },
      {
        label: 'Traffic',
        content: <TrafficContent />,
      },
      {
        label: 'Payment', // payment summary
        content: <PaymentSummary />,
      },
      {
        label: 'Payment Balance',
        content: <PaymentBalance />,
      },
      {
        label: 'Referral Links Report',
        content: <ReferralLinks />,
      },
    ];
  }

  return (
    <Container {...props}>
      <Tabs
        defaultTab={defaultTab}
        tabs={tabList}
        basePath="/reports"
        style={{ zIndex: '6' }}
      />
    </Container>
  );
};

export default Reports;
