import React from 'react';
import { LoadingIconProps } from 'src/types/baseComponentTypes';
import BaseIcon from 'src/components/atoms/BaseIcon/BaseIcon';
import { ReactComponent as IconDot } from 'src/icons/iconDot.svg';
import 'src/components/atoms/LoadingDots/loadingDots.scss';

const LoadingDots = ({ size }: LoadingIconProps) => (
  <div
    className="loadingDots"
    style={size ? ({ '--iconSize': `${size}px` } as React.CSSProperties) : {}}
  >
    <BaseIcon
      className="loadingDots__dot loadingDots__dot--one"
      size={size}
      icon={IconDot}
    />
    <BaseIcon
      className="loadingDots__dot loadingDots__dot--two"
      size={size}
      icon={IconDot}
    />
    <BaseIcon
      className="loadingDots__dot loadingDots__dot--three"
      size={size}
      icon={IconDot}
    />
    <BaseIcon
      className="loadingDots__dot loadingDots__dot--four"
      size={size}
      icon={IconDot}
    />
  </div>
);

export default LoadingDots;
