import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import DealsList from './DealsList';
import { type DealsProps, Deal } from 'src/types/dealsTypes';
import ErrorMessage from 'src/components/atoms/ErrorMessage/ErrorMessage';
import MultiSelectFilter from 'src/components/atoms/MultiSelectFilter/MultiSelectFilter';
import { type MultiSelectItem as Brand } from 'src/types/formTypes';
import BaseButton from 'src/components/atoms/BaseButton/BaseButton';
import { BaseButtonVariant } from 'src/types/baseComponentTypes';
import { ReactComponent as IconFeedback } from 'src/icons/iconStarEmpty.svg';
import {
  default as client,
  addCamelCaseRespInterceptor,
  ejectCamelCaseRespInterceptor,
} from 'src/components/api';
import { UserContext } from 'src/context/UserContext';
import useDealsLocalStorage from 'src/components/templates/Deals/hooks/useDealsLocalStorage';
import 'src/components/templates/Deals/deals.scss';

// if empty array, all brands will be selected by default
const defaultSelectedBrands: string[] = [];

const Deals: React.FC<DealsProps> = () => {
  const { userCredentials } = useContext(UserContext);
  const { password = '', username = '', csrf_token = '' } = userCredentials;
  const [dealsListData, setDealsListData] = useState<Deal[]>([]);
  const [errorMessages, setErrorMessages] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  // extracts unique brands from deals data
  const brands = useMemo(() => {
    const brandSet = new Set<string>();
    return dealsListData
      .filter((deal) => {
        const key = deal.brandName.toLowerCase();
        if (brandSet.has(key)) return false;
        brandSet.add(key);
        return true;
      })
      .map((deal) => ({ id: deal.rowNum, name: deal.brandName }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [dealsListData]);

  // handles brand selection persistence
  const [selectedBrands, setSelectedBrands] = useDealsLocalStorage(
    defaultSelectedBrands,
    brands,
  );

  // fetches deals data when credentials are available
  useEffect(() => {
    // username and password are coming async from local storage therefore it's a race condition
    if (username.length === 0 || password.length === 0) return;

    const fetchDeals = async (retries = 3) => {
      const interceptorId = addCamelCaseRespInterceptor(client);

      try {
        const { data } = await client.get<Deal[]>('/api/deals?_format=json', {
          headers: { csrf_token },
          auth: { username, password },
        });

        // filters out duplicate deals
        const uniqueDeals = data.filter(
          (deal, index, self) =>
            index === self.findIndex((d) => d.rowNum === deal.rowNum),
        );
        setDealsListData(uniqueDeals);
        setErrorMessages(false);

        // extracts + stores unique brands
        const localUniqueBrands = Array.from(
          new Set(uniqueDeals.map((deal) => deal.brandName)),
        );
        console.log('Local unique brands:', localUniqueBrands);

        // compares with stored brands
        const storedUniqueBrands = JSON.parse(
          localStorage.getItem('storedUniqueBrands') || '[]',
        );
        const uniqueBrandsMatch =
          localUniqueBrands.length === storedUniqueBrands.length &&
          localUniqueBrands.every((brand) =>
            storedUniqueBrands.includes(brand),
          );
        console.log('uniqueBrands match =', uniqueBrandsMatch);
      } catch (error) {
        // retries logic for failed requests
        if (retries > 0) {
          fetchDeals(retries - 1);
        } else {
          setErrorMessages(true);
          console.error('Error fetching deals:', error);
        }
      } finally {
        ejectCamelCaseRespInterceptor(client, interceptorId);
        setLoading(false);
      }
    };

    fetchDeals();
  }, [csrf_token, password, username]);

  // handles initial brand selection
  useEffect(() => {
    if (brands.length > 0) {
      const storedBrands = localStorage.getItem('currentlySelectedBrandsList');
      if (storedBrands) {
        try {
          const parsedBrands = JSON.parse(storedBrands);
          // validate stored brands exist in current brands list
          const validBrands = brands.filter((brand) =>
            parsedBrands.some((stored: Brand) => stored.name === brand.name),
          );
          if (validBrands.length > 0) {
            setSelectedBrands(validBrands);
            return;
          }
        } catch (e) {
          console.error('Error parsing stored brands:', e);
        }
      }
      // fallback to default selection logic
      if (defaultSelectedBrands.length === 0) {
        setSelectedBrands(brands);
      } else {
        const filteredBrandsList = brands.filter((brand) =>
          defaultSelectedBrands.includes(brand.name),
        );
        setSelectedBrands(filteredBrandsList);
      }
    }
  }, [brands, setSelectedBrands]);

  // callback for handling brand selection changes
  const handleSelectionChange = useCallback(
    (selected: Brand[]) => {
      setSelectedBrands(selected);
    },
    [setSelectedBrands],
  );

  // filters deals based on selected brands
  const filteredDeals = useMemo(() => {
    return selectedBrands.length
      ? dealsListData.filter((deal) =>
          selectedBrands.some((brand) => brand.name === deal.brandName),
        )
      : [];
  }, [selectedBrands, dealsListData]);

  const handleSelectFeaturedBrands = () => {
    // if default selected brands array is empty, select all brands
    if (defaultSelectedBrands.length === 0) {
      setSelectedBrands(brands);
    } else {
      // else select default selected brands in array
      const filteredBrandsList = brands.filter((brand) =>
        defaultSelectedBrands.includes(brand.name),
      );
      setSelectedBrands(filteredBrandsList);
    }
  };

  return (
    <section
      className={`deals ${filteredDeals.length === 0 ? 'deals--empty' : ''}`}
    >
      <div className="deals__title">
        <h2 className="deals__titleText">
          Trending&nbsp;<span className="deals__titleTextBeta">beta</span>
        </h2>
        <BaseButton
          className="deals__feedbackButton"
          href="/contact-support"
          text="Give Feedback"
          icon={IconFeedback}
          variant={BaseButtonVariant.Black}
        />
      </div>
      <div className="deals__subtitle">
        <p className="deals__subtitleText">
          Discover trending products and best-sellers to share. Pick your
          favorites, grab your link, and start earning!
        </p>
      </div>
      <div className="deals__filters">
        <label className="deals__filtersLabel">Filter by brand</label>
        <MultiSelectFilter
          items={brands}
          selectedItems={selectedBrands}
          onSelectionChange={handleSelectionChange}
          label="brand"
          breakpoint="1300px"
        />
        {errorMessages && <ErrorMessage message="Error fetching deals" />}
      </div>
      <DealsList
        className="deals__dealsList"
        dealsList={filteredDeals}
      />
      {!loading && filteredDeals.length === 0 && (
        <div className="deals__emptyGrid">
          <p className="deals__emptyGridText">
            No brands selected. Explore our featured brands to find great deals.
          </p>
          <BaseButton
            onClick={handleSelectFeaturedBrands}
            className="deals__emptyGridButton"
            text="Featured Brands"
          />
        </div>
      )}
    </section>
  );
};

export default Deals;
