import React from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled, type ThemeProps } from './../../../theme/index';
import Tab from './../../atoms/Tab/Tab';
import SelectInput, { type Option } from './../../atoms/Select/Select';

export interface TabProps {
  label?: string;
  content?: React.ReactNode;
  path?: string;
}

interface Props extends ThemeProps {
  tabs: TabProps[];
  defaultTab?: number;
  basePath: string;
  urlMap?: (label: string) => string;
}

const SelectWrapper = styled('div', {
  display: 'initial',
  '@bp3': {
    display: 'none',
  },
});

const Content = styled(RadixTabs.Content);

const List = styled(RadixTabs.List, {
  display: 'none',
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    display: 'block',
    position: 'absolute',
    left: 0,
    bottom: 0,
    content: ' ',
    width: '100%',
    borderBottom: '2px solid $black',
    pointerEvents: 'none',
  },
  '@bp3': {
    display: 'flex',
  },
});

const Tabs: React.FC<Props> = ({
  defaultTab = 0,
  tabs = [],
  basePath,
  ...props
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const options = React.useMemo(
    () =>
      tabs.map((tab) => ({
        label: tab.label || '',
        value: tab.label || '',
      })) as Option[],
    [tabs],
  );

  // determines the active tab based on the url
  const path = location.pathname.toLowerCase();
  const activeTab = React.useMemo(() => {
    const matchingTab = options.find((opt) => {
      const tabPath = `${basePath}/${opt.value
        .toLowerCase()
        .replace(/\s+/g, '-')}`;
      return path === tabPath;
    });
    return matchingTab || options[defaultTab];
  }, [path, options, basePath, defaultTab]);

  const handleTabChange = (value: string): void => {
    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption) {
      const tabPath = `${basePath}/${selectedOption.value
        .toLowerCase()
        .replace(/\s+/g, '-')}`;
      navigate(tabPath);
    }
  };

  const selectHandler = (selectedOption: Option): void => {
    const tabPath = `${basePath}/${selectedOption.value
      .toLowerCase()
      .replace(/\s+/g, '-')}`;
    navigate(tabPath);
  };

  return (
    <div>
      <RadixTabs.Root
        activationMode="manual"
        value={activeTab.value}
        onValueChange={handleTabChange}
      >
        <List aria-label="Tabs">
          {tabs.map((tab, index) => (
            <Tab
              key={`trigger-${index}`}
              value={tab.label || ''}
            >
              {tab.label}
            </Tab>
          ))}
        </List>
        <SelectWrapper>
          <SelectInput
            options={options}
            value={activeTab}
            onChange={(event) => selectHandler(event as unknown as Option)}
            setValue={function (
              _value: React.SetStateAction<Option | null>,
            ): void {
              throw new Error('Function not implemented.');
            }}
          />
        </SelectWrapper>
        {tabs.map((tab, index) => (
          <Content
            key={`content-${index}`}
            value={tab.label || ''}
            {...props}
          >
            <div>{tab.content}</div>
          </Content>
        ))}
      </RadixTabs.Root>
    </div>
  );
};

export default Tabs;
