import { useEffect, useState, useMemo } from 'react';
import CryptoJS from 'crypto-js';
import { type MultiSelectItem as Brand } from 'src/types/formTypes';

const LOCAL_STORAGE_KEY = 'currentlySelectedBrandsList';
const LOCAL_STORAGE_HASH_KEY = 'hashedDefaultBrandsList';
const LOCAL_STORAGE_UNIQUE_BRANDS_KEY = 'storedUniqueBrands';

const useDealsLocalStorage = (
  defaultSelectedBrands: string[],
  brands: Brand[],
) => {
  const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);

  // maps brand names to brand objects
  const mapDefaultBrands = (
    defaultBrands: string[],
    availableBrands: Brand[],
  ): Brand[] => {
    return defaultBrands
      .map((brand) => {
        const matchingBrand = availableBrands.find((b) => b.name === brand);
        return matchingBrand
          ? { id: matchingBrand.id, name: matchingBrand.name }
          : null;
      })
      .filter((brand): brand is Brand => brand !== null);
  };

  // creates a hash of default brands for comparison
  const defaultBrandsHash = useMemo(() => {
    if (defaultSelectedBrands.length === 0) {
      // gets stored unique brands
      const storedUniqueBrands = localStorage.getItem(
        LOCAL_STORAGE_UNIQUE_BRANDS_KEY,
      );
      if (storedUniqueBrands) {
        // uses stored brands list to generate hash
        const uniqueBrands = JSON.parse(storedUniqueBrands);
        return CryptoJS.SHA256(JSON.stringify(uniqueBrands.sort())).toString(
          CryptoJS.enc.Hex,
        );
      }
      // if no stored brands yet, return empty string
      return '';
    }

    // regular hash calculation for non-empty defaultSelectedBrands array
    return CryptoJS.SHA256(
      JSON.stringify(defaultSelectedBrands.sort()),
    ).toString(CryptoJS.enc.Hex);
  }, [defaultSelectedBrands]);

  useEffect(() => {
    // skips if no brands or already initialized, avoids infinite loop
    if (brands.length === 0 || isInitialized) {
      return;
    }

    // gets stored values from localStorage
    const storedSelectedBrands = localStorage.getItem(LOCAL_STORAGE_KEY);
    const storedBrandsHash = localStorage.getItem(LOCAL_STORAGE_HASH_KEY);
    const storedUniqueBrands = localStorage.getItem(
      LOCAL_STORAGE_UNIQUE_BRANDS_KEY,
    );

    if (storedBrandsHash && storedSelectedBrands && storedUniqueBrands) {
      // checks if stored brands match current brands
      const uniqueBrandsMatch =
        JSON.parse(storedUniqueBrands).length === brands.length &&
        JSON.parse(storedUniqueBrands).every((brand: string) =>
          brands.some((b) => b.name === brand),
        );

      // if hashes match + brands lists match, use stored selection
      if (storedBrandsHash === defaultBrandsHash && uniqueBrandsMatch) {
        const parsedSelectedBrands = JSON.parse(storedSelectedBrands);
        setSelectedBrands(parsedSelectedBrands);
      } else {
        // resets to defaults if no match
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        localStorage.removeItem(LOCAL_STORAGE_HASH_KEY);
        localStorage.removeItem(LOCAL_STORAGE_UNIQUE_BRANDS_KEY);

        const mappedBrands = mapDefaultBrands(defaultSelectedBrands, brands);
        localStorage.setItem(LOCAL_STORAGE_HASH_KEY, defaultBrandsHash);
        localStorage.setItem(
          LOCAL_STORAGE_UNIQUE_BRANDS_KEY,
          JSON.stringify(brands.map((b) => b.name)),
        );
        setSelectedBrands(mappedBrands);
      }
    } else {
      // sets to defaults if any storage items are missing
      const mappedBrands = mapDefaultBrands(defaultSelectedBrands, brands);
      localStorage.setItem(LOCAL_STORAGE_HASH_KEY, defaultBrandsHash);
      localStorage.setItem(
        LOCAL_STORAGE_UNIQUE_BRANDS_KEY,
        JSON.stringify(brands.map((b) => b.name)),
      );
      setSelectedBrands(mappedBrands);
    }

    setIsInitialized(true);
  }, [brands, defaultSelectedBrands, defaultBrandsHash, isInitialized]);

  // keeps local storage in sync with selected brands
  useEffect(() => {
    if (!isInitialized) return;
    if (selectedBrands.length > 0) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(selectedBrands));
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }, [selectedBrands, isInitialized]);

  return [selectedBrands, setSelectedBrands] as const;
};

export default useDealsLocalStorage;
