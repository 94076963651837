export interface BaseIconProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  size?: number;
  className?: string;
}

export interface BaseImageProps {
  src: string;
  placeholderSrc?: string;
  alt: string;
  className?: string;
  width: number;
  height: number;
  defaultSrc?: string;
  lazy?: boolean;
  objectFit?: 'cover' | 'contain' | 'none';
}

export enum TagType {
  Trending = 'Trending',
  HotItem = 'Hot Item',
  LowestPrice = 'Lowest Price',
}

export type BaseButtonProps = BaseButtonUrlProps | BaseButtonClickProps;

interface BaseButtonUrlProps extends BaseButtonCommonProps {
  href: string;
  onClick?: never;
}

interface BaseButtonClickProps extends BaseButtonCommonProps {
  href?: never;
  onClick: () => void;
}

interface BaseButtonCommonProps {
  text: string | React.ReactNode;
  variant?: BaseButtonVariant;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconSize?: number;
  iconPosition?: 'left' | 'right';
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
}

export enum BaseButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Black = 'black',
}

export interface LoadingIconProps {
  size?: number;
}
