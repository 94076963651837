import React from 'react';
import 'src/components/templates/Deals/dealsList.scss';
import DealsListItem from 'src/components/templates/Deals/DealsListItem';
import { type DealsListProps } from 'src/types/dealsTypes';

const DealsList: React.FC<DealsListProps> = ({
  dealsList = [],
  className = '',
  layout = 'grid',
}) => {
  return (
    <ul className={`dealsList dealsList--${layout} ${className}`.trim()}>
      {dealsList.map((deal) => (
        <li
          className="dealsList__item"
          key={deal.rowNum}
        >
          <DealsListItem {...deal} />
        </li>
      ))}
    </ul>
  );
};

export default DealsList;
