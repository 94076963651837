import React, { useState, useEffect } from 'react';
import {
  MultiSelectFilterProps,
  MultiSelectItem,
  TagListLayout,
} from 'src/types/formTypes';
import MultiSelectFilterTags from 'src/components/atoms/MultiSelectFilter/MultiSelectFilterTags';
import MultiSelectFilterDropdown from 'src/components/atoms/MultiSelectFilter/MultiSelectFilterDropdown';
import 'src/components/atoms/MultiSelectFilter/multiSelectFilter.scss';

const MultiSelectFilter: React.FC<MultiSelectFilterProps> = ({
  items,
  selectedItems,
  onSelectionChange,
  breakpoint = null,
  tagLayout = TagListLayout.Carousel,
}) => {
  const [isBreakpointReached, setIsBreakpointReached] = useState<boolean>(
    breakpoint ? window.innerWidth >= parseInt(breakpoint) : false,
  );
  const [layoutClass, setLayoutClass] = useState<string>('');

  useEffect(() => {
    if (!breakpoint) {
      return;
    }

    const handleResize = () => {
      setIsBreakpointReached(window.innerWidth >= parseInt(breakpoint));
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  useEffect(() => {
    const newLayoutClass = isBreakpointReached
      ? tagLayout === TagListLayout.Carousel
        ? 'multiSelectFilterTags--wrap'
        : 'multiSelectFilterTags--carousel'
      : tagLayout === TagListLayout.Carousel
      ? 'multiSelectFilterTags--carousel'
      : 'multiSelectFilterTags--wrap';

    setLayoutClass(newLayoutClass);
  }, [isBreakpointReached, tagLayout]);

  const handleRemoveTag = (item: MultiSelectItem) => {
    onSelectionChange(
      selectedItems.filter((selected) => selected.id !== item.id),
    );
  };

  return (
    <div className="multiSelectFilter">
      <MultiSelectFilterDropdown
        items={items}
        selectedItems={selectedItems}
        onSelectionChange={onSelectionChange}
        label="brand"
      />
      <MultiSelectFilterTags
        selectedItems={selectedItems}
        handleRemoveTag={handleRemoveTag}
        layoutClass={layoutClass}
      />
    </div>
  );
};

export default MultiSelectFilter;
