import React from 'react';
import { MultiSelectFilterTagsProps } from 'src/types/formTypes';
import BaseIcon from 'src/components/atoms/BaseIcon/BaseIcon';
import { ReactComponent as IconClose } from 'src/icons/iconClose.svg';
import 'src/components/atoms/MultiSelectFilter/multiSelectFilterTags.scss';

const MultiSelectFilterTags: React.FC<MultiSelectFilterTagsProps> = ({
  selectedItems,
  handleRemoveTag,
  layoutClass = '',
}) => {
  return (
    <ul className={`multiSelectFilterTags ${layoutClass}`}>
      {selectedItems.map((item) => (
        <li
          key={item.id}
          className="multiSelectFilterTags__item"
        >
          <button
            aria-label={`Remove ${item.name}`}
            className="multiSelectFilterTags__itemButton"
            onClick={() => handleRemoveTag(item)}
          >
            <span className="multiSelectFilterTags__itemButtonText">
              {item.name}
            </span>
            <BaseIcon
              icon={IconClose}
              size={8}
            />
          </button>
        </li>
      ))}
    </ul>
  );
};

export default MultiSelectFilterTags;
