import React, { useEffect, useContext, useState } from 'react';
import BaseImage from 'src/components/atoms/BaseImage/BaseImage';
import { type DealsListItemProps } from 'src/types/dealsTypes';
import ErrorMessage from 'src/components/atoms/ErrorMessage/ErrorMessage';
import BaseTag from 'src/components/atoms/BaseTag/BaseTag';
import BaseIcon from 'src/components/atoms/BaseIcon/BaseIcon';
import { ReactComponent as IconLinkExternal } from 'src/icons/iconLinkExternal.svg';
import UseCopy from 'src/hook/useCopy/useCopy';
import client from 'src/components/api';
import { UserContext } from 'src/context/UserContext';
import useFormattedDateRange from 'src/components/templates/Deals/hooks/useFormattedDateRange';
import ButtonCreateLink from 'src/components/molecules/ButtonCreateLink/ButtonCreateLink';
import ButtonCopyLink from 'src/components/molecules/ButtonCopyLink/ButtonCopyLink';
import 'src/components/templates/Deals/dealsListItem.scss';

const DealsListItem: React.FC<DealsListItemProps> = ({
  rowNum,
  imageUrl,
  title,
  url,
  brandName,
  commissionRate,
  price,
  salePrice,
  code,
  startDate,
  endDate,
  label,
  className = 'dealsListItem--card',
}) => {
  const { userCredentials } = useContext(UserContext);
  const [affiliateLink, setAffiliateLink] = useState('');
  const [createLinkButtonText, setCreateLinkButtonText] =
    useState('Create Link');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [copy, isCopied] = UseCopy();

  const handleCopyLinkClick = () => {
    if (affiliateLink) {
      copy(affiliateLink);
    }
  };

  useEffect(() => {
    if (affiliateLink) {
      setCreateLinkButtonText('Link generated!');
    }
    if (isCopied) {
      setCreateLinkButtonText('Copied to clipboard!');
      // "create a link" button text changes for 2 seconds
      const timer = setTimeout(() => {
        setCreateLinkButtonText('Link generated!');
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isCopied, affiliateLink]);

  const handleGenerateLink = async (args: {
    url: string;
    id: string;
    isShort: boolean;
  }) => {
    setError('');
    setIsLoading(true);

    const dest_url = args.url;
    if (dest_url !== '') {
      const shortLink = args.isShort;
      const aff_sub = '';
      const link_medium = 'web';
      const link_term = 'deals_page';

      const { password, username, csrf_token } = userCredentials;
      if (!password || !username || !csrf_token) {
        setError('User credentials are missing. Please log in again.');
        setIsLoading(false);
        return;
      }

      try {
        const response = await client.post(
          '/api/deep_linking',
          {
            targetUrl: dest_url.trim(),
            short_link: shortLink,
            aff_sub,
            link_medium,
            link_term,
          },
          {
            headers: {
              'Content-type': 'application/x-www-form-urlencoded',
              csrf_token,
            },
            auth: { username, password },
          },
        );

        if (response.status === 200 && response.data.status === undefined) {
          const responseData = response.data;
          if (responseData.url === '') {
            setError(
              'We were unable to create a link, please try again later or contact support for help.',
            );
          } else {
            setError('');
            setAffiliateLink(responseData.url);
          }
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError('An error occurred, contact support for help.');
        console.error('Error generating link:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setError('Destination URL cannot be empty');
      setIsLoading(false);
    }
  };

  const handleGenerateLinkClick = () => {
    handleGenerateLink({ url, id: rowNum.toString(), isShort: true });
  };

  const formattedDateRange = useFormattedDateRange(startDate, endDate);

  return (
    <article className={`dealsListItem ${className}`.trim()}>
      <p className="dealsListItem__commission">
        {commissionRate}&nbsp;Earnings
      </p>
      <div className="dealsListItem__image">
        <BaseImage
          src={imageUrl}
          width={250}
          height={250}
          alt={title}
          objectFit="contain"
          lazy={true}
        />
      </div>
      {label && <BaseTag label={label} />}
      <div className="dealsListItem__panel">
        <p className="dealsListItem__panelBrand">{brandName}</p>
        <a
          className="dealsListItem__panelTitle"
          href={url}
          rel="noreferrer"
          target="_blank"
        >
          <h3 className="dealsListItem__panelTitleText">{title}</h3>
          <BaseIcon
            className="baseIcon--newTab"
            icon={IconLinkExternal}
            size={24}
          />
        </a>
        {(salePrice || price) && (
          <p className="dealsListItem__panelPrice">
            {salePrice && <span>{salePrice} </span>}
            {price && (
              <span className="dealsListItem__panelPriceOriginal">{price}</span>
            )}
          </p>
        )}
        {code && (
          <p className="dealsListItem__panelCode">
            Use Code{' '}
            <span className="dealsListItem__panelCodeText">{code}</span>
          </p>
        )}
        {error && (
          <ErrorMessage
            className="dealsListItem__panelError"
            message={error}
          />
        )}
        {affiliateLink && (
          <ButtonCopyLink
            affiliateLink={affiliateLink}
            handleCopyLinkClick={handleCopyLinkClick}
          />
        )}
        <ButtonCreateLink
          className="dealsListItem__panelButton--createLink"
          affiliateLink={affiliateLink}
          isCopied={isCopied}
          isLoading={isLoading}
          createLinkButtonText={createLinkButtonText}
          handleGenerateLinkClick={handleGenerateLinkClick}
        />
        {formattedDateRange && (
          <p className="dealsListItem__panelValidDates">{formattedDateRange}</p>
        )}
      </div>
    </article>
  );
};

export default DealsListItem;
