// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { styled } from './../../../theme/index';
import SocialItems from './../../molecules/SocialItems/SocialItems';
import { footerMenuLinks } from './../../../theme/menuLinks';
import './footer.scss';

interface Props {
  isLogin?: boolean;
}

const Container = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  background: '$black',
  color: ' $clear',
  position: 'relative',
  padding: '0 20px 40px',
  '@media(min-width: 600px)': {
    padding: '0 55px 40px',
  },
  '@media(min-width: 992px)': {
    padding: '0 40px 0',
  },
  variants: {
    isLogin: {
      true: {
        padding: '13px 40px 32px',
        '@media(min-width: 992px)': {
          padding: '35px 40px',
        },
      },
    },
  },
});

const Wrapper = styled('div', {
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  maxWidth: '100%',
  width: '100%',
  '@media(min-width: 576px)': {
    maxWidth: '510px',
  },
  '@media(min-width: 765px)': {
    maxWidth: '100%',
  },
  '@media(min-width: 768px)': {
    maxWidth: '744px',
  },
  '@media(min-width: 989px)': {
    maxWidth: '964px',
  },
  '@media(min-width: 992px)': {
    flexDirection: 'row',
    maxWidth: '930px',
  },
  '@media(min-width: 1200px)': {
    maxWidth: '100%',
  },
  variants: {
    isLogin: {
      true: {
        maxWidth: '990px',
      },
    },
  },
});

const CopyRight = styled('div', {
  flex: '0 2 auto',
  fontFamily: '$body',
  fontSize: '13px',
  padding: '12px',
  lineHeight: 3.75,
  variants: {
    isLogin: {
      true: {
        lineHeight: 1.5,
      },
    },
  },
});

const LinksWrapper = styled('div', {
  padding: '10px',
  flexGrow: '1',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  flexDirection: 'column',
  maxWidth: '468px',
  '@media(min-width: 480px)': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    width: '80%',
  },
  '@media(min-width: 992px)': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    maxWidth: '100%',
    width: 'max-content',
  },
  variants: {
    isLogin: {
      true: {
        padding: '10px 30px 20px',
        flexGrow: '0',
        flexDirection: 'row',
        flex: '80%',
        '@media(min-width: 480px)': {
          flexDirection: 'row',
          display: 'flex',
        },
        '@media(min-width: 764px)': {
          flexWrap: 'nowrap',
          maxWidth: '100%',
        },
        '@bp3': {
          padding: '10px 30px',
          flex: 'initial',
        },
      },
    },
  },
});

const Link = styled('a', {
  padding: '10px',
  marginRight: '17px',
  textAlign: 'center',
  display: 'inline-block',
  textDecoration: 'none',
  fontFamily: '$body',
  fontSize: '$size15',
  fontWeight: '$semibold',
  textTransform: 'capitalize',
  cursor: 'pointer',
  color: '$clear',
  '&:hover': {
    textDecoration: 'underline',
  },
  variants: {
    isLogin: {
      true: {
        '&:hover': {
          textDecoration: 'none',
          color: '$green',
        },
      },
    },
  },
});

const SocialWrapper = styled('div', {
  marginTop: '20px',
  '@bp3': {
    marginTop: 0,
  },
  variants: {
    isLogin: {
      true: {
        marginTop: 0,
      },
    },
  },
});

// eslint-disable-next-line react/prop-types
const Footer: React.FC<Props> = ({ userFooterMenu = [], isLogin }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [footerMenu, setFooterMenu] = useState(footerMenuLinks);
  const year = new Date().getFullYear();

  const socialType = isLogin === true ? 'home' : 'admin';

  useEffect(() => {
    if (userFooterMenu.length > 0) {
      setFooterMenu(userFooterMenu);
    }
  }, [userFooterMenu]);

  return (
    <Container
      className="footer"
      isLogin={isLogin}
    >
      <Wrapper isLogin={isLogin}>
        <CopyRight isLogin={isLogin}>
          Copyright &copy; {year} BrandCycle
        </CopyRight>
        <LinksWrapper isLogin={isLogin}>
          {footerMenu.map((element, index) => {
            if (element?.show === 'side') {
              return null;
            }

            return (
              <Link
                key={`${element.label} ${index}`}
                type="secondary"
                href={element.src}
                css={element?.css}
                isLogin={isLogin}
              >
                {element.label}
              </Link>
            );
          })}
        </LinksWrapper>
        <SocialWrapper isLogin={isLogin}>
          <SocialItems
            type={socialType}
            fontAwesomeIcon
          />
        </SocialWrapper>
      </Wrapper>
    </Container>
  );
};

export default Footer;
