import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import Tabs from './../../molecules/Tabs/Tabs';
import ToolsContent from './../../molecules/ToolsContent/ToolsContent';
import MobileAppContent from './../../molecules/MobileAppContent/MobileAppContent';
import BuildALinkContent from './../../molecules/BuildALinkContent/BuildALinkContent';
import BrowserExtensionContent from '../../molecules/BrowserExtensionContent/BrowserExtensionContent';

interface Props extends ThemeProps {
  defaultTab?: number;
}

const Container = styled('div', {
  display: 'block',
  maxWidth: '100%',
});

const Tools: React.FC<Props> = ({ defaultTab, ...props }) => {
  const tabList = [
    {
      label: 'Extension',
      path: '/extension',
      content: <BrowserExtensionContent />,
    },
    {
      label: 'Build a Link',
      path: '/build-a-link',
      content: <BuildALinkContent />,
    },
    {
      label: 'Mobile App',
      path: '/mobile-app',
      content: <MobileAppContent />,
    },
    {
      label: 'Bookmarklet',
      path: '/bookmarklet',
      content: <ToolsContent />,
    },
  ];

  return (
    <Container {...props}>
      <Tabs
        defaultTab={defaultTab}
        basePath=""
        tabs={tabList}
        urlMap={(label: string) => {
          const tab = tabList.find((t) => t.label === label);
          return tab?.path || '';
        }}
        css={{
          marginTop: '42px',
        }}
      />
    </Container>
  );
};

export default Tools;
